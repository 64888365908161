@import '../../../../scss/theme-bootstrap';

.product-brief-shades {
  padding: 0 0 7px;
  margin: 0;
  list-style: none;
  @include breakpoint($medium-up) {
    padding: 0;
  }
  .selected {
    border: 1px solid $color-white;
    box-shadow: $color-black 0 0 1px 1px;
  }
  .shade-inventory-status--4,
  .shade-inventory-status--7 {
    &:after {
      content: ' ';
      position: relative;
      #{$rdirection}: 10px;
      top: -10px;
      border-#{$rdirection}: 1px solid $color-black;
      transform: rotate(45deg);
      display: block;
      width: 100%;
      height: 100%;
      #{$ldirection}: unset;
      border-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        width: 95%;
        height: 95%;
        #{$rdirection}: 5px;
        top: -5px;
      }
    }
  }
  .product-brief-shades__grid--layout {
    .shade-inventory-status--4,
    .shade-inventory-status--7 {
      &:after {
        #{$rdirection}: 14px;
        top: -14px;
      }
    }
  }
  &__header {
    margin: 6px 0 8px;
    width: 100%;
    display: block;
    padding-#{$ldirection}: 5px;
    @include breakpoint($medium-up) {
      margin: 5px 0;
      padding-#{$ldirection}: 0;
    }
  }
  &__title {
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
    text-align: center;
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 345px;
    .tabbed-products-block & {
      max-width: 330px;
      @include breakpoint($medium-up) {
        max-width: 100%;
      }
    }
  }
  &__name {
    display: inline-block;
    clear: none;
    color: $color-black-2;
    font-size: 13px;
    font-family: $body-font-bold;
    text-transform: uppercase;
    margin: 0;
  }
  &__badge {
    text-transform: uppercase;
    display: block;
    clear: none;
    color: $color-pink;
    font-size: 12px;
    line-height: 1.6;
    position: relative;
    margin-#{$ldirection}: 3px;
    @include breakpoint($medium-up) {
      display: inline-block;
      margin-#{$ldirection}: 0;
      padding: 0 3px;
    }
  }
  &__toggle-grid {
    display: none;
  }
  &__grid {
    margin: 0 auto;
    max-width: 295px;
    overflow-x: visible;
    &-inner {
      width: 100%;
    }
    &--layout {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      max-height: 53px;
      overflow: hidden;
      padding-top: 2px;
      .product-brief-shades[aria-expended='true'] & {
        max-height: 100%;
      }
      .product-brief-shades__shade-item {
        min-width: 16.66%; // 6 shades per row
        text-align: center;
        margin: 0 0 10px;
        padding: 0;
        .product-brief-shades__shade {
          margin: 0 auto;
          width: 43px;
          height: 43px;
        }
      }
    }
    &--layout ~ .product-brief-shades__footer {
      .product-brief-shades__toggle-grid {
        display: inline-block;
        width: 100%;
        border-top: solid 1px $color-very-light-pink;
        &--show-label,
        &--hide-label {
          padding: 5px 10px;
          margin: 0 auto;
          border: solid 1px $color-very-light-pink;
          font-weight: bold;
          font-size: 11px;
          cursor: pointer;
          color: $color-black-2;
          &:before {
            margin-bottom: 3px;
            margin-#{$rdirection}: 5px;
            color: $color-black-2;
            font-weight: bold;
          }
        }
        &--hide-label {
          @include icon('minus', 'before', false, false, 8px);
          display: none;
          .product-brief-shades[aria-expended='true'] & {
            display: inline-block;
          }
        }
        &--show-label {
          @include icon('plus', 'before', false, false, 8px);
          display: inline-block;
          .product-brief-shades[aria-expended='true'] & {
            display: none;
          }
        }
      }
    }
    @include breakpoint($medium-up) {
      max-width: 270px;
      padding: 0 15px;
    }
  }
  &__shade {
    width: 33px; // if they are any larger they don't fit on mobile
    height: 33px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    display: block;
    border: 1px solid $color-white;
    box-shadow: $color-white 0 0 1px 1px;
    @include breakpoint($medium-up) {
      width: 19px;
      height: 19px;
    }
  }
  &__shade-item {
    margin-#{$rdirection}: 17px;
    padding-#{$ldirection}: 3px;
    float: #{$ldirection};
    overflow: visible;
    display: block;
    height: auto;
    @include breakpoint($medium-up) {
      margin: 1.5px;
      padding: 1.5px;
    }
  }
  @include breakpoint($medium-up) {
    .slick-list {
      margin-top: 8px;
    }
  }
  .slick-track {
    padding: 3px 0;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .slick-prev {
    min-width: 12px;
    min-height: 12px;
    #{$ldirection}: -24px;
    top: 15px;
    position: absolute;
    @include breakpoint($medium-up) {
      #{$ldirection}: -10px;
      top: 6px;
    }
  }
  .slick-next {
    min-width: 12px;
    min-height: 12px;
    #{$rdirection}: -26px;
    top: 15px;
    position: absolute;
    &:before {
      #{$rdirection}: auto;
      position: relative;
      height: auto;
    }
    @include breakpoint($medium-up) {
      #{$rdirection}: -7px;
      top: 6px;
    }
  }
  .slick-next,
  .slick-next:before,
  .slick-prev,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1.5;
    color: $color-black;
  }
  &__personalization {
    height: 22px;
    display: block;
    text-align: center;
    width: auto;
    overflow: auto;
    &.active {
      height: 22px;
      width: 100%;
    }
  }
  &__hex {
    display: none; // it will be displayed on content block product v1 only.
  }
}
